<template>
  <article class="prose dark:prose-invert max-w-none prose-sm sm:prose-base">
    <h2 class="text-lg font-bold text-gray-500 dark:text-gray-400">
      {{ $t('home.aboutMe.title') }}
    </h2>
    <p>
      {{ $t('home.aboutMe.introduction') }}
    </p>
    <p>
      {{ $t('home.aboutMe.passion') }}
    </p>
  </article>
</template>
