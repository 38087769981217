<template>
  <main>
    <div class="flex flex-col flex-nowrap gap-10">
      <Hero />
      <AboutMe />
      <HomeTabs />
    </div>
  </main>
</template>

<script lang="ts" setup>
const { t } = useI18n()
useSeoMeta({
  title: () => t('home.hero.myName'),
  description: 'เว็บไซต์ของ Jittiphat Somsai - สร้างด้วย Nuxt 3 + TailwindCSS.',
  author: 'Jittiphat Somsai',
  ogTitle: '%s - Personal Website',
  ogDescription: 'เว็บไซต์ของ Jittiphat Somsai - สร้างด้วย Nuxt 3 + TailwindCSS.',
  creator: 'Jittiphat Somsai',
  ogImage: '/index-page.png',
  ogUrl: 'https://jittiphat.site',
  ogLocale: 'th_TH',
  ogSiteName: 'Jittiphat Somsai',
  titleTemplate: '%s - Personal Website',
})
</script>
