<template>
  <section class="body-font mb-0 text-gray-600 lg:mb-5">
    <UContainer class="flex flex-col-reverse items-center justify-between gap-5 pt-4 lg:flex-row" :ui="{ padding: 'px-0 lg:px-0', base: 'mx-auto' }">
      <div
        class="prose prose-sm flex max-w-none flex-grow basis-full flex-col items-center justify-center sm:prose-base dark:prose-invert prose-h1:mb-0 lg:items-start"
      >
        <h1 class="text-3xl font-bold sm:text-4xl">
          {{ $t('home.hero.myName') }}
        </h1>
        <p class="text-center lg:text-left text-balance">
          {{ $t('home.hero.intro') }}
        </p>
        <div class="flex w-full flex-row items-center justify-center gap-2 lg:justify-start">
          <UButton
            icon="ph:folder-open-duotone"
            color="primary"
            size="lg"
            to="/Projects"
          >
            My Projects
          </UButton>
        </div>
      </div>
      <div class="basis-1/3">
        <NuxtImg
          src="https://res.cloudinary.com/dgreflq7m/image/upload/v1729761110/My%20Brand/jittiphat_kbkgqw.jpg"
          class="aspect-square size-40 rounded-lg shadow-md md:size-48 lg:size-full dark:shadow-none"
          width="100%"
          height="100%"
          alt="Picture"
          aria-label="Picture"
        />
      </div>
    </UContainer>
    <!-- </div> -->
  </section>
</template>
